function Navbar(){
    return(
    <nav className="navbar navbar-dark bg-dark">
        <div className="container-fluid">
            <span className="navbar-brand mb-0 h1">CML Amministrazioni condominiali Lapegna</span>
        </div>
    </nav>
    );
}

export default Navbar;
