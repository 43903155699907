import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
import Card from './components/card';


class App extends Component {
 render(){
  return (
    <>
      <Navbar />
      <div className='container'>
        <h2> Amministrazione d'immobili & <p/>
             Servizi per amministratori condominiali <p/> </h2> 
        <div className='row'>
          <Card titolo="Chi Siamo" />
          <Card titolo="I nostri servizi" />
          <Card titolo="Contattaci" />
        </div>
      </div>
    </>
  );
};
}

export default App;
