import React, {Component} from 'react';
import Cat from '../image/cat.jpg';

class Card extends Component{
    render(){
    return(
        <div className="col">
            <div className="card" style={{width: '18rem', textAlign: 'center'}}>
                <img src={Cat} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="card-title">{this.props.titolo}</h5>
                    <p className="card-text">Un gattino in cerca di affetto XD.</p>
                    <a className="btn btn-primary">Clicca per adottarlo</a>
                </div>
            </div>
        </div>
        );
    }
}

export default Card;